exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coaching-tsx": () => import("./../../../src/pages/coaching.tsx" /* webpackChunkName: "component---src-pages-coaching-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-functions-tsx": () => import("./../../../src/pages/functions.tsx" /* webpackChunkName: "component---src-pages-functions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pool-tsx": () => import("./../../../src/pages/pool.tsx" /* webpackChunkName: "component---src-pages-pool-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-snooker-tsx": () => import("./../../../src/pages/snooker.tsx" /* webpackChunkName: "component---src-pages-snooker-tsx" */)
}

